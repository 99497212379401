button {
  background: transparent;
  border: none;
}

.User {
  width: 100%;
  margin: 0 auto;
  background: rgb(255 255 255 / 100%);
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  height: 100%;
}

.UserInfo {
  display: flex;
  max-width: 882px;
  margin: 31px 10px 37px;
  width: calc(100% - 20px);
}

.UserData {
  width: 100%;
}

.UserInfoItem {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.UserInfoItemName {
  margin: 0;
  font-size: 17px;
  color: var(--border-gray);
  width: 138px;
}

.UserInfoItemValue {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000;
  width: calc(100% - 160px);
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
  font-weight: 400;
  padding-bottom: 6px;
  padding-top: 3px;
}

.UserInfoItemValue::-webkit-calendar-picker-indicator {
  padding-left: calc(100% - 110px);
  margin-top: -4px;
  cursor: pointer;
  color: transparent;
  opacity: 1;
  background: url('./../../assets/svg/arrow-01.svg') no-repeat center right;
}

.Block1Img {
  height: 180px;
  width: 236px;
  margin: 6px 38px 0 0;
}

.Block1ImgBlock {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Avatar {
  width: 140px;
  height: 140px;
  border-radius: 100%;
  z-index: 3;
  background: white;
  object-fit: cover;
}

.DeleteAvatarBlock {
  background: rgb(180 194 199);
  position: absolute;
  height: 40px;
  width: 28px;
  padding: 3px 0 0;
  transform: rotate(45deg);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  left: 110px;
  top: 33px;
  z-index: 1;
  transition: 0.2s all ease;
}

.DeleteAvatar {
  mask-image: url('./../../assets/svg/delete-01.svg');
  width: 15px;
  height: 16px;
  background: var(--bg-primary);
  mask-repeat: no-repeat;
  margin: 0 0 2px 1px;
  cursor: pointer;
  transform: rotate(-45deg);
}

.EditAvatarBlock {
  background: rgb(180 194 199);
  position: absolute;
  height: 40px;
  width: 28px;
  padding: 3px 0;
  transform: rotate(45deg);
  border-radius: 100px;
  justify-content: center;
  left: 50%;
  top: 25%;
  z-index: 1;
  align-items: flex-end;
  display: flex;
  transition: 0.2s all ease;
}

.EditAvatar {
  mask-image: url('./../../assets/svg/edit-01.svg');
  width: 15px;
  height: 16px;
  background: var(--bg-primary);
  word-wrap: none;
  mask-repeat: no-repeat;
  margin: 0 0 2px 1px;
  cursor: pointer;
  transform: rotate(-45deg);
}

.Block1ImgBlock:hover > .EditAvatarBlock {
  left: 26px;
  top: 97px;
}

.Block1ImgBlock:hover > .DeleteAvatarBlock {
  top: -2px;
  left: 127px;
}

.profileInfoItem {
  display: flex;
  align-items: center;
}

.profile {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 43px;
}

.profileVisual {
  height: 100%;
  width: 18%;
}

.profileInfo {
  width: 82%;
}

.status {
  display: flex;
  align-items: center;
  margin: 42px 0 0 14px;
}

.profileInfoItem {
  margin-bottom: 16px;
}

._ {
  max-width: 680px;
  margin: 0 20px 0 172px;
  width: calc(100% - 192px);
}

h3 {
  font-family: Ubuntu-R;
  padding-left: 38px;
  font-size: 21px;
  color: #b0c3c8;
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 27px 0 38px;
  height: 218px;
  background: #f1f7fc;
  border-radius: 5px;
}

.passwordItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  position: relative;
}

.passwordItem span {
  padding-right: 10px;
  font-size: 17px;
  width: 129px;
  min-width: 129px;
}

.input {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  color: grey;
  background: transparent;
  padding: 10px 0;
}

.button {
  width: 170px;
  height: 36px;
  background: var(--color-brand);
  color: var(--bg-primary);
  border-radius: 30px;
  margin-top: 29px;
  margin-left: 23px;
  cursor: pointer;
  font-size: 15px;
}

.button_edit {
  width: 170px;
  height: 36px;
  min-height: 36px;
  background: var(--color-brand);
  color: var(--bg-primary);
  border-radius: 30px;
  cursor: pointer;
  font-size: 15px;
}

.passwordError {
  font-family: Ubuntu-R;
  font-size: 11pt;
  color: red;
  margin-left: 174px;
}

.functionalButtons {
  display: flex;
  align-items: center;
  max-width: 680px;
  margin: 0 0 60px 225px;
  width: calc(100% - 192px);
}

.button_cancel {
  width: 170px;
  height: 36px;
  min-height: 36px;
  color: var(--text-secondary);
  border: 1px solid;
  border-radius: 30px;
  cursor: pointer;
  font-size: 15px;
  margin-left: 20px;
}

.errorBlock {
  position: absolute;
  display: flex;
  margin: 52px 0 0 138px;
}

.InputError {
  border-bottom: 1px solid #c12d2d;
}

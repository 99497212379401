.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  background: rgb(19 57 43 / 54%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  margin: 10px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  z-index: 99999999;
}

.modal {
  font-size: 17px;
  width: calc(80vw - 220px);
  padding: 15px;
  background: var(--bg-secondary);
  border-radius: 5px;
  display: grid;
  align-content: space-between;
  grid-template-rows: 60px auto 50px;
}

.footer {
  margin: 10px auto;
  display: flex;
}

.rowType {
  margin: 20px 0;
}

.dataErrorBlock {
  display: flex;
  margin: 20px 0 0;
}

.infoItem {
  width: 100%;
  margin-bottom: 20px;
  color: var(--text-primary);
  display: grid;
  grid-template-columns: 40% 60%;
}

.modalBody {
  padding: 20px 40px 20px 20px;
  overflow: auto;
  height: 60vh;
  max-height: 1400px;
}

.infoItem div:first-child {
  max-width: 280px;
}

.headerTitle {
  margin: 0 0 0 10px;
  font-size: 25px;
}

.categoryTitle {
  margin: 20px auto;
  border: 2px dashed gray;
  text-align: center;
  padding: 10px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.standartFieldContainerFirst {
  margin: 10px 0;
}

.standartFieldContainer {
  margin: 40px 0 10px;
}

.dataError {
  color: #c12d2d;
  font-family: Ubuntu-R;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.7;
  margin-left: 5px;
}

.dataErrorBlock {
  position: absolute;
  display: flex;
  margin: -3px 0 0;
}

.dataErrorBlockDinamyc {
  margin: -22px 0 0;
  right: 60px;
}

.userFieldContainer {
  position: relative;
}

.categoryTitle {
  margin: 20px auto;
  border: 2px dashed gray;
  text-align: center;
  padding: 10px 0;
}

.textField {
  position: relative;
}

.itemRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 20px;
  margin-bottom: 20px;
}

.input {
  border: none;
  border-bottom: 1px solid var(--border-gray);
  font-family: Ubuntu-R;
  font-size: 15px;
  padding: 2px;
  width: 100%;
  letter-spacing: 0.04em;
  background: none;
}

.inputContainer {
  display: flex;
  gap: 15px;
}

.buttonClear {
  position: relative;
  height: 21px;
  width: 30px;
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b0e1df;
  margin-left: 10px;
}

.buttonClear div {
  width: 13px;
  height: 13px;
}

.itemImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.containerImg {
  width: 62px;
  height: 62px;
  overflow: hidden;
}

.itemIcon {
  border: 1px solid rgb(0 0 0 / 50.4%);
  height: 91px;
  display: grid;
  place-items: center center;
  padding: 2px;
  cursor: pointer;
}

.bg_blue {
  background-color: #1100ff5d;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 50%);
}

.iconsContainer {
  width: 1000px;
  max-height: 420px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  overflow-y: scroll;
  gap: 10px;
  margin-bottom: 20px;
}

.formFieldContainer {
  display: flex;
  margin: 0 0 40px;
}

.iconsField {
  position: relative;
}

.dataErrorBlock {
  position: absolute;
  bottom: -20px;
  left: 0;
}

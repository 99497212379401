.workRightManual {
  height: 100%;
  width: 100%;
  color: var(--text-primary);
  position: relative;
}

.workRightManual_countRows {
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-secondary);
  margin-left: 15px;
}

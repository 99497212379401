.workRightTable {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  display: block;
  height: calc(100% - 110px);
  min-height: 450px;
}

.workRightTable_sample {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  display: block;
  height: 78%;
  min-height: 450px;
}

.workRightTable_sample_filter {
  height: 54%;
}

.workRightTable_sample td {
  width: 9%;
}

.workRightTableHeader {
  font-family: Ubuntu-M;
  font-size: 17px;
  color: var(--text-primary);
  padding: 15px 0 10px 10px;
}

.workRightTableRow {
  transition: 0.2s all ease;
}

.workRightTableRow:hover {
  background: #dce6e9;
  transition: 0.2s all ease;
}

.workRightTableRow:hover .workRightTableItem {
  background: #dce6e9;
  transition: 0.2s all ease;
}

.workRightTableRow:hover > td > div > .workRightTableItem_Button {
  background-color: #1aba66;
  transition: 0.2s all ease;
}

.workRightTableItem_Button {
  transition: 0.2s all ease;
  background-color: white;
  height: 20px;
  width: 25px;
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-position: center;
  mask-size: contain;
  mask-size: contain;
  float: left;
  cursor: pointer;
}

.workRightTableItem {
  transition: 0.2s all ease;
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-primary);
  border-bottom: 1px solid #dce6e9;
  border-top: 1px solid #dce6e9;
  padding: 10px;
}

.workRightTableItem_last {
  max-width: 200px;
}

.workRightTableItemCount {
}

.workRightTableItemColor {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.workRightTableSortUpNot {
  mask-image: url('../../../../assets/svg/triangle-01.svg');
  width: 15px;
  height: 15px;
  background-color: slategrey;
  mask-repeat: no-repeat;
  transform: rotate(180deg);
  margin: 0 0 2px 1px;
  cursor: pointer;
}

.workRightTableSortDownNot {
  mask-image: url('../../../../assets/svg/triangle-01.svg');
  width: 15px;
  height: 15px;
  background-color: slategrey;
  mask-repeat: no-repeat;
  cursor: pointer;
}

.workRightTableSortUp {
  mask-image: url('../../../../assets/svg/triangle-01.svg');
  background-color: var(--color-brand);
  width: 15px;
  height: 15px;
  mask-repeat: no-repeat;
  transform: rotate(180deg);
  margin: -5px 0 0 10px;
  cursor: pointer;
}

.workRightTableSortDown {
  mask-image: url('../../../../assets/svg/triangle-01.svg');
  background-color: var(--color-brand);
  width: 15px;
  height: 15px;
  margin: 4px 0 0 10px;
  mask-repeat: no-repeat;
  cursor: pointer;
}

.workRightTableSortBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 19px;
  align-items: center;
  width: 15px;
  margin-left: 10px;
}

.workRightTableHeaderBlock {
  display: flex;
  align-items: center;
}

.workRightTable_Image {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  object-fit: cover;
}

.workRightTable_icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.headerTableContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emptyBlock {
  width: 20%;
  color: transparent;
}

.workRightTableItemString {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 800;
}

.workRightTableItemArray {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 800;
}

.containerImg {
  object-fit: contain;
  width: 80%;
  height: 80%;
}

.workRightTableItemSample {
  border-radius: 100%;
  background: #50b160;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  font-size: 25px;
  font-family: Ubuntu-R;
  border: 1px solid var(--border-gray);
  cursor: pointer;
}

.workRightTableItemSampleImg {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  font-size: 25px;
  border: 1px solid var(--border-gray);
  cursor: pointer;
}

.sharedContainer {
  margin: 20px;
  display: flex;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: rgb(217 231 234 / 100%);
  flex-direction: column;
}

.sharedBody {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.filterRow {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 10px;
}

.filterSelector {
  position: relative;
  width: 250px;
  margin-right: 20px;
}

.infoItemValue {
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
  font-size: 16px;
  color: var(--text-primary);
  background-color: transparent;
  height: 21px;
}

.deleteIco {
  cursor: pointer;
  margin-left: 10px;
}

.footer {
  display: flex;
  flex-direction: row;
}

.asdf {
  width: 20px;
  height: 20px;
}

.footer button {
  margin-right: 20px;
}

.deviceTrueSelected {
  background-color: rgb(128 128 128 / 10%);
}

.deviceTrue {
  width: 18px;
  height: 18px;
  border: 2px solid grey;
  cursor: pointer;
}

.selectType {
  width: 150px;
}

.rigthArrow {
  transform: rotate(-90deg);
}

.bottomArrow {
  transform: rotate(-180deg);
}

.expandedBody {
  background: rgb(242 247 253 / 100%);
  transition: 0.2s all ease;
}

.workRightTableExpandedRowUserfieldItem {
  transition: 0.2s all ease;
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-primary);
  padding: 5px 10px 0 0;
}

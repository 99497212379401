.modal {
  width: 800px;
}

.table {
  width: 100%;
}

.header {
  display: flex;
}

.header > div {
  padding: 5px;
}

.body {
  margin-top: 30px;
  max-height: 250px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-collapse: separate;
  border-spacing: 10px;
}

.body::-webkit-scrollbar {
  width: 0.5em;
  background-color: transparent;
}

.body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.body::-webkit-scrollbar-track {
  background-color: transparent;
}

.tableRow {
  display: flex;
  width: 100%;
  background-color: #ffff;
}

.tableRow > div {
  padding: 5px;
  outline: 1px solid black;
}

.itemDate {
  text-align: center;
  width: 20%;
}

.itemUser {
  text-align: center;
  width: 20%;
}

.itemAction {
  text-align: center;
  width: 30%;
}

.itemDescription {
  text-align: center;
  width: 30%;
}

.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  background: rgb(19 57 43 / 54%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  font-size: 17px;
  width: 615px;
  padding: 15px;
  background: var(--bg-secondary);
  border-radius: 5px;
  display: grid;
  align-content: space-between;
  grid-template-rows: 60px auto 50px;
}

.footer {
  margin: 10px auto;
  display: flex;
}

.rowType {
  margin: 20px 0;
}

.dataErrorBlock {
  display: flex;
  margin: 20px 0 0;
}

.infoItem {
  width: 100%;
  margin-bottom: 20px;
  color: var(--text-primary);
  display: grid;
  grid-template-columns: 40% 60%;
}

.modalBody {
  padding: 20px 40px 20px 20px;
}

.infoItem div:first-child {
  max-width: 280px;
}

.headerTitle {
  margin: 0 0 0 10px;
  font-size: 25px;
}

.categoryTitle {
  margin: 20px auto;
  border: 2px dashed gray;
  text-align: center;
  padding: 10px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.standartFieldContainerFirst {
  margin: 10px 0;
}

.standartFieldContainer {
  margin: 40px 0 10px;
}

.dataError {
  color: #c12d2d;
  font-family: Ubuntu-R;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.7;
  margin-left: 5px;
}

.dataErrorBlock {
  position: absolute;
  display: flex;
  margin: -3px 0 0;
}

.dataErrorBlockDinamyc {
  margin: -22px 0 0;
  right: 60px;
}

.userFieldContainer {
  position: relative;
}

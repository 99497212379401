.location_group {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.input_group {
  position: relative;
  gap: 1em;
  display: flex;
  flex-wrap: wrap;
}

.input {
  resize: none;
  border: none;
  border-bottom: 1px solid var(--border-gray);
  font-family: Ubuntu-R;
  font-size: 15px;
  padding: 2px;
  width: 100%;
  letter-spacing: 0.04em;
  background: none;
  cursor: pointer;
  overflow: hidden;
}

.arrow {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
  transition: 0.1s;
}

.flippedArrowIcon {
  transform: translateY(-50%) rotate(180deg);
}

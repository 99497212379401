.main {
}

.loader {
  border: 6px solid var(--bg-secondary);
  border-top: 6px solid var(--color-brand);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-top: -60px;
  margin-left: -60px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

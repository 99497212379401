.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  overflow: auto;
}

.body {
  padding-top: 20px;
}

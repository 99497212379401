.infoItem {
  width: 100%;
  display: flex;
  color: var(--text-primary);
}

.infoItemName {
  width: 160px;
  min-width: 160px;
  margin: 0 15px 0 0;
  font-weight: 500;
  color: var(--text-primary);
}

.infoItemInput {
  width: calc(100% - 140px);
  position: relative;
  color: var(--text-primary);
}

.infoItemValue {
  width: 100%;
  border: none;
  background: transparent;
  border-bottom: 1px solid black;
  color: var(--text-primary);
  font-family: Ubuntu-R;
  font-size: 12pt;
  padding: 0 0 6px;
}

.infoItemArrow {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 15%;
  padding: 0;
}

.TreeBlock {
  position: absolute;
  background: #f4f7fc;
  border-radius: 10px;
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow: auto;
  padding: 10px 10px 12px;
  z-index: 5;
  top: 28px;
  font-family: Ubuntu-R;
}

.DropdownBlockAll {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
}

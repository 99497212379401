.parent > * {
  margin-left: 0 !important;
}

.Item {
  transition: 0.3s all ease;
  margin-left: 30px;
  position: relative;
}

.ItemName {
  cursor: pointer;
  display: flex;
  margin: 0 10px 0 0;
  white-space: nowrap;
}

.ItemNameSelect {
  background: rgb(176 225 223);
}

.Strelka {
  transition: 0.3s all ease;
  margin: 11px 0 0;
}

.tripod {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: var(--color-brand);
  padding: 2px;
}

.tripodOrange {
  background-color: #c27e00;
}

.SVG {
  //background-color: var(--text-primary);
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-position: center;
  mask-size: contain;
  mask-size: contain;
  float: left;
  cursor: pointer;
  margin: 0 5px 0 0;
  //filter: invert(16%) sepia(79%) saturate(320%) hue-rotate(106deg) brightness(95%) contrast(97%);
}

.ItemNameBlock:hover > .SVG {
  //background-color: var(--color-brand);
  filter: invert(51%) sepia(36%) saturate(6359%) hue-rotate(122deg) brightness(97%) contrast(103%);
}

.Line {
  border-left: 1px solid #bbbbbb;
  border-bottom: 1px solid #bbbbbb;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -24px;
  top: -5px;
  transition: 0.3s all ease;
}

.Line2 {
  transition: 0.3s all ease;
  border-left: 1px solid #bbbbbb;
  height: 100%;
  position: absolute;
  left: -24px;
}

.Block {
  display: flex;
}

.ItemNameBlock:hover > .Block > .Item_Button {
  background-color: var(--color-brand);
}

.Item_Button {
  background-color: #dce6e9;
  height: 17px;
  width: 25px;
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-position: center;
  mask-size: contain;
  mask-size: contain;
  float: left;
  cursor: pointer;
}

.Item_Btn {
  background-color: var(--color-brand);
  height: 17px;
  width: 25px;
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-position: center;
  mask-size: contain;
  mask-size: contain;
  float: left;
  cursor: pointer;
}

.ItemNameBlock:hover > .InvBlock {
  border-bottom: 1px solid #bbbbbb;
}

.ItemNameBlock:hover > .ItemNameText {
  color: var(--color-brand);
}

.InvBlock {
  width: 100%;
}

.ItemNameBlock {
  display: flex;
  width: 100%;
  padding: 6px 0 6px 5px;
}

.ButtonActive {
  background: rgb(176 225 223);
}

.dropZone {
  width: 93%;
  margin-left: 13px;
  margin-bottom: 2px;
  height: 20px;
  margin-top: 5px;
  background-color: rgb(0 134 255 / 10%);
  border: 1px dashed rgb(0 134 255 / 100%);
}

.infoItemValue {
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
  font-size: 16px;
  color: var(--text-primary);
  background-color: transparent;
  padding: 0 0 5px;
  height: 21px;
}

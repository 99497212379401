.autocomplete-container {
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid;
  border-color: #738087 !important;
}

.autocomplete-input {
  font-size: 15px;
  color: var(--text-primary);
  font-family: Ubuntu-R;
  border: unset;
  background-color: transparent;
  min-height: 25px;
}

.autocomplete-suggestions {
  font-size: 15px;
  color: var(--text-primary);
  font-family: Ubuntu-R;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f4f7fc;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
}

.autocomplete-suggestion {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.autocomplete-suggestion:hover {
  background-color: #f0f0f0;
}

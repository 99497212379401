.pageContainer {
  padding: 0;
}

.actionButtonIconContainer {
  width: 20px;
  height: 20px;
}
.toolbarActionButtonContainer {
  background: var(--color-brand);
  border-radius: 50%;
  cursor: pointer;
  padding: 8px;
}
.toolbarActionButton {
  width: 18px;
  height: 18px;
}

.deviceDetails {
  padding: 10px 15px 10px 40px;

  .title {
    margin-bottom: 20px;
    padding: 10px 0 0 0;
  }
}

.detailsField {
  display: flex;
  padding-top: 5px;
  font-family: Ubuntu-R;
  font-size: 16px;
  color: var(--text-primary);
}

.detailsField > div:first-child {
  margin-right: 10px;
  font-family: Ubuntu-R;
  font-size: 14px;
  font-style: italic;
}

.regularText {
  font-family: Ubuntu-R;
  font-size: 16px;
  color: var(--text-primary);
}

.blockTitle {
  margin: 20px 30px 6px 0;
  min-width: 112px;
  font-size: 17px;
  font-family: Ubuntu-M;
  position: relative;
}

.label {
  margin-right: 30px;
  font-family: Ubuntu-R;
  font-size: 16px;
  color: var(--text-primary);
}
.inv {
  color: gray;
  margin: 0 10px;
  font-size: 16px;
}

.itemImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.containerImg {
  width: 72px;
  height: 72px;
  overflow: hidden;
}

.itemIcon {
  border: 1px solid rgb(0 0 0 / 50.4%);
  height: 91px;
  display: grid;
  place-items: center center;
  padding: 2px;
}

.iconsContainer {
  width: 120px;
  max-height: 420px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  overflow-y: scroll;
  gap: 10px;
  margin-bottom: 20px;
}

.table {
  border-collapse: collapse;
  table-layout: auto;
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-primary);
  border: 1px dashed gray;

  .cell {
    border-top: 1px dashed gray;
    border-left: 1px dashed gray;
    padding: 4px 40px;
  }
}

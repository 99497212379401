.title {
  font-size: 17px;
  color: #bbbbbb;
}

.checkbox_container {
  display: flex;
  flex-direction: row;
}

.square {
  width: 17px;
  height: 17px;
  margin-right: 10px;
  margin-left: 10px;
  border: 2px solid gray;
  display: flex;
}

.square:hover {
  cursor: pointer;
  background-color: transparent;
}

.default {
  margin: 2px;
  width: 13px;
  height: 13px;
}

.full {
  margin: 2px;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  background-image: url('../../../assets/svg/button_on-02.svg');
}

.part {
  margin: 2px;
  width: 13px;
  height: 13px;
  background-color: #0f9119;
}

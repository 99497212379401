.workRightSelectPage {
  display: flex;
  margin: 8px 10px 12px 0;
  justify-content: flex-end;
}

.workRightSelectPage_str_left {
  transform: rotate(90deg);
  height: 10px;
}

.workRightSelectPage_str_left_block {
  background: #e4eff2;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  border-radius: 20px 0 0 20px;
  border: 1px solid var(--border-gray);
  cursor: pointer;
  user-select: none;
  user-select: none;
  user-select: none;
}

.workRightSelectPage_str_right {
  transform: rotate(-90deg);
  height: 10px;
}

.workRightSelectPage_str_right_block {
  background: #e4eff2;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  border-radius: 0 20px 20px 0;
  border: 1px solid var(--border-gray);
  cursor: pointer;
  margin: 0 0 0 -1px;
  user-select: none;
  user-select: none;
  user-select: none;
}

.workRightSelectPage_item {
  border: 1px solid var(--border-gray);
  height: 33px;
  width: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0 0 0 -1px;
  cursor: pointer;
  user-select: none;
  user-select: none;
  user-select: none;
}

.workRightSelectPage_item_select {
  border: 1px solid var(--border-gray);
  height: 33px;
  width: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--bg-primary);
  background: var(--color-brand);
  margin: 0 0 0 -1px;
  user-select: none;
  user-select: none;
  user-select: none;
}

.tables {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  gap: 10px;
  min-height: 440px;
}

.containerHeader {
  display: flex;
  gap: 60px;
  margin-bottom: 10px;
  align-items: end;
}

.tableLeftContainer,
.tableRightContainer {
  max-height: 600px;
  overflow-y: auto;
  background-color: #fff;

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
  }
}

.infoBlock {
  width: 50%;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

th {
  text-align: left;
}

td {
  word-wrap: break-word;
}

.tableHeader {
  background-color: #f1f7fc;
}

.tableHeader .selectedCell {
  visibility: hidden;
}

.column:first {
  padding: 10px;
}

.column {
  padding: 10px 0 10px 10px;
}

.row {
  border-top: 1px solid #c3cccf;
}

.row:last-of-type {
  border-bottom: 1px solid #dce6e9;
}

th.selectAll {
  max-width: 20px;
}

.cell {
  padding: 10px;
}

.cell:first-of-type {
  max-width: 37px;
  padding: 10px 0 10px 0;
}

th:last-of-type {
  padding-right: 10px;
}

.leftArrow {
  transform: translate(-1px) rotate(90deg);
}

.rightArrow {
  transform: translate(1px) rotate(270deg);
}

.moveButton {
  display: flex;
  background-color: var(--color-brand);
  border-radius: 5px;
  padding: 10px;
}
.selectedCell {
  width: 13px;
  height: 13px;
  margin-right: 10px;
  margin-left: 10px;
  border: 2px solid gray;
  display: flex;
  padding: 0 !important;
  align-self: center;
  align-items: center;
  cursor: pointer;
}


.collaps {
  border: 1px solid gray;
  width: 10px;
  height: 10px;
  padding: 0 !important;
  margin: 0;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.moveButton:disabled {
  opacity: 0.5;
}

.noData {
  border-top: 1px solid #c3cccf;
  padding: 10px;
  text-align: center;
}

tr {
  max-height: fit-content;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
}

.itemsCounter {
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-secondary);
}

.paging {
  display: flex;
  align-items: center;
  gap: 50px;
}

.paginationPages {
  display: flex;
}

.paginationPage {
  border: 1px solid #dce6e9;
  background: #fff;
  height: 33px;
  width: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0 0 0 -1px;
  cursor: pointer;
  user-select: none;
}

.currentPage {
  color: #fff;
  cursor: default;
  background-color: var(--color-brand); /* Этот стиль может перекрывать .active */
}

.leftArrow {
  transform: rotate(90deg);
  height: 10px;
  user-select: none;
}

.leftArrowButton {
  background: #e4eff2;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  border-radius: 20px 0 0 20px;
  border: 1px solid #dce6e9;
  cursor: pointer;
  user-select: none;
}

.leftArrowButton:disabled {
  cursor: default;
  opacity: 0.5;
}

.rightArrow {
  transform: rotate(-90deg);
  height: 10px;
}

.rightArrowButton {
  background: #e4eff2;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  border-radius: 0 20px 20px 0;
  border: 1px solid #dce6e9;
  cursor: pointer;
  margin: 0 0 0 -1px;
  user-select: none;
  user-select: none;
  user-select: none;
}

.rightArrowButton:disabled {
  cursor: default;
  opacity: 0.5;
}

.minusInCollaps {
  display: block;
  border-bottom: 2px solid gray;
  width: 100%;
  padding: 0 !important;
}

.tooltip {
  position: absolute;
  top: 35px;
  left: 0;
  right: auto;
  width: auto;
  display: none;
  background-color: #f9f9f9;
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;
  z-index: 4000;
  color: var(--border-gray);
}

.tooltipInfo {
  top: 15px !important;
}

.tooltip:hover {
  display: none !important;
}

.collaps:hover .tooltip {
  display: block;
}

.moreInfoRow {
  background-color: #f8f9fa;
  transition: all 0.3s ease-in-out;
}

.moreInfoRow td {
  padding: 12px 16px;
  border-top: 1px solid #dce6e9;
}

.moreInfoRowTextNormal {
  font-size: 14px;
  font-weight: normal;
  color: #333;
  word-break: break-word;
  padding-left: 10px;
}

.moreInfoRowContent {
  flex-direction: column;
  gap: 5px;
}

.sortContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 19px;
  align-items: center;
  width: 15px;
  margin-left: 10px;
}

.sortDown {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.sortUp {
  width: 15px;
  height: 15px;
  transform: rotate(180deg);
  cursor: pointer;
}

.visibleOff {
  display: none !important;
}

.cellContainer {
  display: flex;
  align-items: center;
}
.textField {
  position: relative;
}

.formFieldContainer {
  display: flex;
  width: 607px;
  margin: 0 0 40px;
}

.formField {
  margin: 40px 0 40px;
}

.dataError {
  color: #c12d2d;
  font-family: Ubuntu-R;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.7;
  margin-left: 5px;
}

.dataErrorBlock {
  position: absolute;
  display: flex;
  margin: 0 0 10px 0;
  top: 35px;
}

.label {
  margin-right: 50px;
  width: 112px;
  min-width: 112px;
  font-size: 16px;
  font-family: Ubuntu-M;
  position: relative;
}

.container {
  margin: 0 5%;
}

.input {
  border: none;
  border-bottom: 1px solid var(--border-gray);
  font-family: Ubuntu-R;
  font-size: 15px;
  padding: 2px;
  width: 100%;
  letter-spacing: 0.04em;
  background: none;
}

.inputContainer {
  width: 450px;
}

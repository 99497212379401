.flexbox-fix:nth-child(3) > div {
  display: none;
  visibility: hidden;
}

.flexbox-fix:nth-child(3) > div > div {
  margin-bottom: -20px;
}

.sketch-picker > .flexbox-fix:nth-child(2) {
  position: relative;
  justify-content: center;
}

.flexbox-fix:last-child > div > span > div {
  border-radius: 100% !important;
  width: 20px !important;
  height: 20px !important;
}

.sketch-picker > .flexbox-fix:nth-child(2) > div {
  margin-right: -5px;
  margin-left: 20px;
}

.sketch-picker > .flexbox-fix:nth-child(2) > div:first-child > div {
  margin-bottom: 5px;
  margin-top: 15px;
  cursor: pointer;
  min-height: 20px;
  min-width: 175px;
}

.sketch-picker > .flexbox-fix:nth-child(2) > div:last-child {
  position: absolute;
  right: calc(100% - 60px);
  top: 82px;
}

.sketch-picker .flexbox-fix:last-child {
  border-top: unset !important;
  padding-top: 2px !important;
}

.sketch-picker .flexbox-fix:last-child > div {
  margin-right: 10px !important;
  margin-left: 8px !important;
}

.sketch-picker .flexbox-fix:last-child > div:nth-child(7) {
  margin-left: 26px !important;
}

.sketch-picker {
  margin-left: auto !important;
  border: unset !important;
  box-shadow: unset !important;
  padding-top: 12px !important;
  padding-bottom: 8px !important;
}

.sketch-picker > .flexbox-fix:nth-child(2) > div:last-child > div {
  height: 32px !important;
  min-width: 32px !important;
  left: -20px !important;
  top: -9px !important;
  bottom: unset;
  border-radius: 20px !important;
}

.flexbox-fix:nth-child(3) {
  margin-left: auto;
  margin-bottom: -10px;
  height: 65px;
  width: 60%;
}

.flexbox-fix:nth-child(3) label {
  display: none !important;
  visibility: hidden;
}

.flexbox-fix:nth-child(3) input {
  min-height: 30px;
  border-radius: 20px;
}

.flexbox-fix > div:first-child {
  display: block;
  visibility: visible;
}

.saturation-white div:last-child {
  width: 8px !important;
  height: 8px !important;
}

.hue-horizontal {
  max-height: 8px;
  border-radius: 20px;
  margin-top: 3px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.hue-horizontal div:last-child > div {
  width: 16px !important;
  height: 16px !important;
  border: 2px solid var(--bg-primary);
  transform: translate(-8px, -5px) !important;
}

.hue-horizontal div:last-child {
  border-radius: 100% !important;
}

.sketch-picker {
  position: absolute;
  top: 40px;
  right: -12px;
}

.sketch-picker > .flexbox-fix:nth-child(2) > div:first-child > div:last-child {
  width: 175px;
}

.sketch-picker > .flexbox-fix:nth-child(2) > div:first-child > div:last-child > div {
  max-height: 8px;
  max-width: calc(92% + 2px) !important;
  margin-left: 6px;
  margin-top: 3px;
}

.sketch-picker > .flexbox-fix:nth-child(2) > div:first-child > div:last-child > div > div {
  border-radius: 20px;
  margin: 0 3px !important;
}

.sketch-picker
  > .flexbox-fix:nth-child(2)
  > div:first-child
  > div:last-child
  > div
  > div
  > div
  div {
  width: 16px !important;
  height: 16px !important;
  border: 2px solid var(--bg-primary);
  transform: translate(-8px, -5px) !important;
  border-radius: 100% !important;
}

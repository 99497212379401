.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-bottom: 200px;
}

.body {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.title {
  font-size: 25px;
  font-family: Ubuntu-R;
  font-weight: 600;
  color: lightgray;
  margin: 10px 0 20px 20px;
}

.control_body {
  border-radius: 10px;
  background-color: #f4f7fc;
  padding: 20px;
  width: 100%;
}

.infoItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.infoItem_column {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.infoItemName {
  display: block;
  min-width: 160px;
  color: var(--text-primary);
  font-size: 17px;
  margin: 0;
}

.w200px {
  width: 270px;
}

.mb5 {
  margin-bottom: 5px;
}

.infoItemValue {
  border: none;
  border-bottom: 1px solid #000;
  margin-left: 10px;
  width: 100%;
  font-size: 16px;
  color: var(--text-primary);
  background-color: transparent;
  padding: 0 0 5px;
  height: 21px;
}

.checkBoxItem {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.checkbox {
  margin-right: 20px;
}

.checkbox:hover {
  cursor: pointer;
}

.checkbox::before {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  top: -3px;
  height: 20px;
  border: 2px solid #555555;
  border-radius: 3px;
  background-color: white;
}

.checkbox:checked::after {
  content: '';
  display: block;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -1px;
  left: 12px;
}

.checkbox:checked::before {
  background-color: var(--color-brand);
  border-color: var(--color-brand);
}

.footer {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.loadDataContainer {
  display: flex;
  flex-direction: column;
  background: #f4f7fc;
  width: 100%;
  max-width: 95%;
  border-radius: 10px;
  margin-top: 50px;
  min-height: 400px;
  padding: 20px;
  overflow: hidden;
}

.loadDataContainer .span {
  font-family: Ubuntu-R;
  color: gray;
  margin-left: 30px;
}

.table {
  width: auto;
  display: grid;
  overflow: scroll;
  height: 100%;
  position: relative;
  margin-top: 20px;
  min-height: 400px;
  max-height: 600px;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  align-items: start;
}

.tableContainer {
  min-height: 400px;
  max-height: 600px;
  overflow: auto;
}

.row {
  grid-column: 1;
  display: grid;
  grid-gap: 20px;
  padding: 20px;
  margin-bottom: 10px;
}

.rowItem {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  word-wrap: anywhere;
  justify-content: start;
}

.bodyTable {
  background: white;
  height: auto;

  /* margin-top: 150px; */
  border-radius: 10px;
  box-shadow: 0 0 10px 1px rgb(34 60 80 / 20%);
  box-shadow: 0 0 10px 1px rgb(34 60 80 / 20%);
  box-shadow: 0 0 10px 1px rgb(34 60 80 / 20%);
  grid-row-gap: 0;
}

.bodyTable .row {
  color: gray;
  border-top: 1px solid #e8eaef;
  margin-bottom: 0;
}

.selected {
  background: #e8eaef;
}

.preloader {
  margin-top: 50px;
}

.header {
  grid-column: 1;
  display: grid;
  grid-gap: 20px;
  padding: 20px;
  margin-bottom: 10px;

  /* overflow: hidden; */

  /* position: absolute; */
}

.countRow {
  font-family: Ubuntu-R;
  font-size: 17px;
  margin-left: 15px;
  margin-bottom: 20px;
}

.nameRow {
  font-family: Ubuntu-R;
  font-size: 17px;
  margin-left: 15px;
  margin-bottom: 20px;
}

.selectRowContainer {
  display: flex;
}

.selectRow {
  font-family: Ubuntu-R;
  font-size: 17px;
  margin-left: 15px;
  margin-bottom: 20px;
  margin-right: 20px;
  display: flex;
}

.selectRowLable {
  margin-right: 20px;
}

.selectRowDropdown {
  width: 250px;
}

.searchDevice {
  position: relative;
  display: flex;
  align-items: center;
}

.deviceTrue {
  width: 18px;
  height: 18px;
  border: 2px solid grey;
  cursor: pointer;
}

.deviceTrueSelected {
  background-color: rgb(128 128 128 / 10%);
}

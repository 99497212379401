.pageContainer {
  padding: 20px 5%;
}

.formFieldContainer {
  display: flex;
  width: 607px;
  margin: 0 0 10px;
}

.formField {
  margin: 40px 0 40px;
}

.title {
  width: 365px;
  text-align: center;
  padding: 12px 0;
  border-radius: 10px;
  margin: 0 0 30px 0;
  font-size: 20px;
  font-family: Ubuntu-R;
  background-color: #f1f7fc;
  border-radius: 10px;

  justify-content: center;
}

.label {
  margin-right: 50px;
  width: 112px;
  min-width: 112px;
  font-size: 16px;
  font-family: Ubuntu-M;
  position: relative;
}

.container {
  margin: 0 5%;
}

.input {
  border: none;
  border-bottom: 1px solid var(--border-gray);
  font-family: Ubuntu-R;
  font-size: 15px;
  padding: 2px;
  width: 100%;
  letter-spacing: 0.04em;
  background: none;
}

.inputContainer {
  width: 450px;
}

.footer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 40px;
}

.dataError {
  color: #c12d2d;
  font-family: Ubuntu-R;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.7;
  margin-left: 5px;
}

.dataErrorBlock {
  position: absolute;
  display: flex;
  margin: -3px 0 0 0;
}

.addButtonContainer {
  background: var(--color-brand);
  border-radius: 50%;
  cursor: pointer;
  padding: 8px;
}
.addButton {
  width: 12px;
  height: 12px;
}

.modelSelectContainer {
  display: flex;
  margin: 40px 0 0;
}

.addNewModelContainer {
  display: flex;
  height: min-content;
  margin: 0 20px;
  align-items: center;
  text-align: center;
  padding: 0 0 0 0;
  gap: 10px;
  font-family: Ubuntu-R;
  font-size: 15px;
  letter-spacing: 0.04em;
  color: var(--border-gray);
  cursor: pointer;
}

.addNewModelContainer:hover {
  text-decoration: underline;
}

.tooltip {
  background-color: #f9f9f9;
  color: var(--border-gray);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  pointer-events: none;
  white-space: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  font-family: Ubuntu, sans-serif;
  font-weight: 500;
  font-size: 17px;
}

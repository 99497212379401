.btnAddContainer {
  position: relative;
  cursor: pointer;
  display: flex;
  width: 40%;
}

.iconAddContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.textAddContainer {
  margin-right: 5px;
  padding: 5px 5px 5px 0;
  user-select: none;
  font-size: 1em;
}

.selectTextDD {
  position: absolute;
  top: 25px;
  width: 100%;
  display: block;
  max-height: 200px;
  overflow-y: scroll;
  background-color: #f2f8fd;
  z-index: 1;
  padding: 10px 0 10px 10px;
  box-shadow: 0 5px 10px 5px rgb(89 102 109 / 17%);
  border-radius: 5px;
  color: #59666d;
  font-weight: normal;
  font-family: Ubuntu, sans-serif;
}

.selectTextDDopacity {
  display: none;
}

.selectTextItemDD {
  padding: 10px;
}

.selectTextItemDD:hover {
  background-color: #a2e3df;
}

.WorkLeftHeader_search {
  background: #f4f7fc;
  border-radius: 100px;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 400px;
}

.WorkLeftHeader_search_svg {
  height: 15px;
  padding: 0 6px 0 7px;
}

.WorkLeftHeader_search_input {
  background: #f4f7fc;
  width: 100%;
  border-radius: 0 100px 100px 0;
  border: none;
  font-size: 15px;
  font-family: Ubuntu-R;
}

.WorkLeftHeader_search {
  background: #f4f7fc;
  border-radius: 100px;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 400px;
}

.WorkLeftHeader_search_svg {
  height: 15px;
  padding: 0 6px 0 7px;
}

.WorkLeftHeader_search_input {
  background: #f4f7fc;
  width: 100%;
  border-radius: 0 100px 100px 0;
  border: none;
  font-size: 15px;
  font-family: Ubuntu-R;
}

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

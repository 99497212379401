.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.body {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 40px;
}

.title {
  font-size: 20px;
  font-family: Ubuntu-R;
  font-weight: 600;
  color: rgb(128 128 128 / 50%);
  margin: 10px 0 20px 20px;
}

.control_body {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f7fc;
  padding: 20px;
  width: 100%;
}

.control_body_item {
  display: flex;
  align-items: center;
  margin: 20px;
}

.control_body p {
  width: auto;
  font-family: Ubuntu-R;
  margin-right: 10px;
  white-space: nowrap;
}

.control_body div {
  width: 100%;
}

.infoItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.infoItem_column {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.infoItemName {
  display: block;
  min-width: 160px;
  color: var(--text-primary);
  font-size: 17px;
  margin: 0;
}

.w200px {
  width: 270px;
}

.mb5 {
  margin-bottom: 5px;
}

.infoItemValue {
  border: none;
  border-bottom: 1px solid #000;
  margin-left: 10px;
  width: 100%;
  font-size: 16px;
  color: var(--text-primary);
  background-color: transparent;
  padding: 0 0 5px;
  height: 21px;
}

.checkBoxItem {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.checkbox {
  margin-right: 20px;
}

.checkbox:hover {
  cursor: pointer;
}

.checkbox::before {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  top: -3px;
  height: 20px;
  border: 2px solid #555555;
  border-radius: 3px;
  background-color: white;
}

.checkbox:checked::after {
  content: '';
  display: block;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -1px;
  left: 12px;
}

.checkbox:checked::before {
  background-color: var(--color-brand);
  border-color: var(--color-brand);
}

.footer {
  display: flex;
  flex-direction: row;
}

.cell:first-of-type {
  padding: 15px 0 10px 20px;
}

.cell {
  padding: 15px 0 10px 15px;
  border-top: 1px solid #dce6e9;
}

.cellAction {
  position: sticky;
  z-index: 100;
  background-color: #fff;
  right: 0;
}

.cellAction::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #dce6e9;
}

.cellContainer {
  display: flex;
  align-items: center;
  font-size: 17px;
  color: var(--text-primary);
  font-weight: 400;
  font-family: Ubuntu-M;
  text-align: left;
}

.sortDown {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.sortUp {
  width: 15px;
  height: 15px;
  transform: rotate(180deg);
  cursor: pointer;
}

.visibleOff {
  display: none !important;
}

.sortContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 19px;
  align-items: center;
  width: 15px;
  margin-left: 10px;
}

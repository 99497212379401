.workRightTableRow {
  transition: 0.2s all ease;
}

.workRightTableItem {
  transition: 0.2s all ease;
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-primary);
  border-bottom: 1px solid #dce6e9;
  border-top: 1px solid #dce6e9;
  padding: 10px;
}

.workRightTableItem_last {
  max-width: 200px;
}

.workRightTableItem_Button {
  transition: 0.2s all ease;
  background-color: white;
  height: 20px;
  width: 25px;
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-position: center;
  mask-size: contain;
  mask-size: contain;
  float: left;
  cursor: pointer;
}

.workRightTableItem_show {
  mask-image: url('../../../../assets/svg/manual-01.svg');
}

.workRightTableItem_button_delete {
  mask-image: url('../../../../assets/svg/delete-01.svg');
}

.workRightHeader {
  display: flex;
  padding: 0 25px 5px 15px;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  position: relative;
}

.workRightHeader_block {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.workRightHeader_add_block {
  background: var(--color-brand);
  margin-left: 20px;
  border-radius: 100px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  position: relative;
}

.workRightHeader_add {
  mask-image: url('../../../../assets/svg/add-01.svg');
  background-color: white;
  height: 36px;
  width: 35px;
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-position: center;
}

.WorkLeftHeader_search {
  background: #f4f7fc;
  border-radius: 100px;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 400px;
  position: relative;
}

.WorkLeftHeader_search_svg {
  height: 15px;
  padding: 0 6px 0 7px;
}

.WorkLeftHeader_search_input {
  background: #f4f7fc;
  width: 100%;
  border-radius: 0 100px 100px 0;
  border: none;
  font-size: 15px;
  font-family: Ubuntu-R;
}

.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  background: rgb(19 57 43 / 54%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  font-size: 17px;
  width: 515px;
  padding: 15px 30px;
  background: var(--bg-secondary);
  border-radius: 5px;
}

.header {
  display: flex;
  justify-content: end;
  align-items: center;
}

.lable {
  width: 80%;
  margin-right: 10px;
}

.bodyRow {
  margin: 20px 0;
  display: flex;
}

.closeButton {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  margin: 10px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
}

.footer {
  margin: 30px auto 10px;
  display: flex;
  justify-content: center;
}

.infoItemValue {
  width: 100%;
  border: none;
  background: transparent;
  border-bottom: 1px solid black;
  color: var(--text-primary);
  font-family: Ubuntu-R;
  font-size: 12pt;
  padding: 0 0 6px;
}

.infoItemInput {
  width: calc(100% - 140px);
  position: relative;
  color: var(--text-primary);
}

.input {
  resize: none;
  border: none;
  border-bottom: 1px solid var(--border-gray);
  font-family: Ubuntu-R;
  font-size: 15px;
  padding: 2px;
  width: 100%;
  letter-spacing: 0.04em;
  background: none;
  overflow: hidden;
}

.Widget {
  width: 614px;
  height: 271px;
  background: var(--bg-secondary);
  border-radius: 5px;
  position: relative;
}

.WidgetBlock {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background: rgb(19 57 43 / 54%);
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.WidgetClose {
  background: white;
  padding: 10px;
  height: 15px;
  border-radius: 100px;
  float: right;
  margin: 9px 9px 16px 0;
  cursor: pointer;
}

.WidgetBody {
  font-family: Ubuntu-R;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.WidgetButton {
  font-family: Ubuntu-R;
  background: var(--color-brand);
  color: white;
  display: flex;
  align-items: center;
  width: 130px;
  min-width: 130px;
  justify-content: space-evenly;
  border-radius: 100px;
  height: 36px;
  cursor: pointer;
  border: none;
  font-size: 15px;
}

.WidgetIcon {
  width: 160px;
  height: 160px;
  margin: 0 30px;
}

.WidgetIcon270 {
  width: 170px;
  height: 160px;
  margin: 0 30px;
}

.WidgetBodyBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
}

.WidgetHeader {
  font-family: Ubuntu-M;
  font-size: 17px;
  white-space: pre-wrap;
  margin: 0 0 -15px;
}

.WidgetButtonBlock {
  display: flex;
}

.WidgetButtonCancel {
  font-family: Ubuntu-R;
  color: var(--text-secondary);
  border: 1px solid;
  display: flex;
  align-items: center;
  width: 130px;
  min-width: 110px;
  justify-content: space-evenly;
  border-radius: 100px;
  height: 33px;
  margin: 0 20px 5px 0;
  cursor: pointer;
}

.WidgetButtonYesRed {
  font-family: Ubuntu-R;
  background: #c12c2c;
  color: white;
  display: flex;
  align-items: center;
  width: 130px;
  min-width: 130px;
  justify-content: space-evenly;
  border-radius: 100px;
  height: 36px;
  cursor: pointer;
  border: none;
  font-size: 15px;
}

.WidgetButtonInfo {
  font-family: Ubuntu-R;
  background: #2ac49e;
  color: white;
  display: flex;
  align-items: center;
  width: 130px;
  min-width: 130px;
  justify-content: space-evenly;
  border-radius: 100px;
  height: 36px;
  cursor: pointer;
  border: none;
  font-size: 15px;
}

.WidgetButtonYesOrange {
  font-family: Ubuntu-R;
  background: #ff6f03;
  color: white;
  display: flex;
  align-items: center;
  width: 130px;
  min-width: 130px;
  justify-content: space-evenly;
  border-radius: 100px;
  height: 36px;
  cursor: pointer;
  border: none;
  font-size: 15px;
}

.WidgetBodyBlock2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.WidgetText {
  font-weight: 500 !important;
}

.modal {
  width: 50%;
  height: 700px;
  max-height: 700px;
}

.bodyConainer {
  display: flex;
  justify-content: space-between;
  height: 500px;
}

.treeContainer {
  width: 50%;
  padding: 0 10px;
  margin-right: 10px;
  overflow-y: scroll;
}

.treeTitle {
  width: 100%;
  background-color: #a2e3df;
  padding: 5px;
}

.sampleTitle {
  width: 100%;
  background-color: #a2e3df;
  padding: 5px;
}

.sampleContainer {
  width: 35%;
}

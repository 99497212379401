.contextMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  border: 1px solid #0f9119;
  background-color: rgb(217 231 235 / 100%);
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Ubuntu, sans-serif;
  font-weight: 500;
  font-size: 17px;
  z-index: 4001;
  width: max-content;
}

.contextMenuItem {
  padding: 10px;
}

.contextMenuItem:hover {
  background-color: #a2e3df;
}

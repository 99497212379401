.WorkLeftHeader {
  display: flex;
  padding: 15px;
  align-items: center;
}

.WorkLeftHeader_favorite {
  cursor: pointer;
  min-width: 20px;
}

.tooltipContainer {
  position: relative;
  margin-right: 10px;
}

.tooltipContainerCopy {
  margin-right: 0;
  margin-left: 5px;
}

.tooltipContainer:hover .tooltip {
  display: block;
}

.tooltip:hover {
  display: none !important;
}

.WorkLeftHeader_search {
  background: #f4f7fc;
  border-radius: 100px;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 400px;
  position: relative;
}

.WorkLeftHeader_search_svg {
  height: 15px;
  padding: 0 6px 0 7px;
}

.WorkLeftHeader_search_input {
  background: #f4f7fc;
  width: 100%;
  border-radius: 0 100px 100px 0;
  border: none;
  font-size: 15px;
  font-family: Ubuntu-R;
}

.tooltip {
  position: absolute;
  top: 24px;
  left: 0;
  right: auto;
  width: auto;
  display: none;
  background-color: #f9f9f9;
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;
  z-index: 4000;
  color: var(--border-gray);
}

.workRightHeader_add_block {
  background: var(--color-brand);
  margin-left: 5px;
  border-radius: 100px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  position: relative;
}

.workRightHeader_add_block:hover .tooltip {
  display: block;
}

.tooltipRefresh {
  top: 34px;
}

.workRightHeader_refresh {
  mask-image: url('../../../assets/svg/refresh-01.svg');
  background-color: white;
  height: 36px;
  width: 35px;
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-position: center;
}

.workRightHeader_refresh:hover .tooltip {
  display: block;
}

.WorkLeftHeader_add {
  font-family: Ubuntu-R;
  background: var(--color-brand);
  color: white;
  display: flex;
  align-items: center;
  width: 110px;
  min-width: fit-content;
  padding: 0 10px;
  justify-content: space-evenly;
  border-radius: 100px;
  height: 36px;
  margin: 0 0 0 10px;
  cursor: pointer;
  letter-spacing: -0.03em;
  position: relative;
}

.WorkLeftHeader_add_disabled {
  cursor: not-allowed;
}

.WorkLeftHeader_add:hover .tooltip {
  display: block;
}

.tooltipAdd {
  top: 34px;
}

.WorkLeftHeader_add_plus {
  mask-image: url('../../../assets/svg/arrow-01.svg');
  background-color: white;
  height: 10px;
  width: 10px;
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-position: center;
  padding: 0 3px 0 5px;
  margin: 0 0 0 4px;
}

.WorkLeftHeader_copy {
  cursor: pointer;
  margin-left: 10px;
}

.WorkLeftHeader_add_plus_Dropdown {
  position: absolute;
  top: 34px;
  background: #f4f7fc;
  border-radius: 10px;
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  height: fit-content;
  overflow: auto;
  padding: 10px 0 12px;
  z-index: 5000;
  width: 210px;
}

.WorkLeftHeader_add_plus_Dropdown_item {
  align-items: center;
  color: var(--text-primary);
  display: flex;
  font-family: Ubuntu-R;
  height: 30px;
  min-height: 30px;
  padding-left: 5px;
  position: relative;
  cursor: pointer;
}

.WorkLeftHeader_add_plus_Dropdown_item img {
  width: 25px;
  height: 16px;
  margin-right: 10px;
}

.WorkLeftHeader_add_plus_Dropdown_item:hover {
  background: #a2e3df;
}

.DropdownBlock {
  width: 100%;
  height: 100%;
  cursor: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
}

.WorkRightHeader_filter_printBlock {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.PrintBlock {
  position: absolute;
  border: 1px solid #50b160;
  border-radius: 5px;
  background: #f4f7fc;
  width: 219px;
  top: 200px;
  left: 190px;
  z-index: 1;
}

.PrintBlockHeader {
  padding: 10px 10px 2px 14px;
  font-family: Ubuntu-M;
}

.PrintBlockItem {
  cursor: pointer;
  padding: 7px 0 7px 28px;
  font-family: Ubuntu-R;
}

.PrintBlockItem:hover {
  transition: 0.3s all ease;
  background-color: #a2e3df;
}

.row {
  border-top: 1px solid #dce6e9;
  border-bottom: 1px solid #dce6e9;
}

.row:hover {
  background: #dce6e9;
  td {
    background: #dce6e9;
  }
}

.cell:first-of-type {
  padding: 10px 15px 10px 20px;
}

.cell {
  position: sticky;
  background-color: #fff;
  right: 0;
  padding: 5px 10px;
}

.cell::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #dce6e9;
}

.selectedCell {
  width: 18px;
  height: 18px;
  border: 2px solid grey;
  cursor: pointer;
  background-color: rgb(128 128 128 / 10%);
}

.actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.hiddenRow {
  transform: rotate(-90deg);
}

.actionCell {
  display: flex;
  gap: 10px;
}

.expandButton {
  cursor: pointer;
}

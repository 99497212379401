.Work {
  width: 100%;
  height: 82vh;
  display: flex;
}

.Work_resize {
  width: 3px;
  height: 100%;
  cursor: col-resize;
}

.Work_resize_block {
  height: calc(100% - 155px);
  width: 100%;
  position: absolute;
  cursor: col-resize;
}

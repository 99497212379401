._ {
  width: 200px;
  height: 50px;
  background: var(--bg-primary);
  border-radius: 10px;
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  position: absolute;
  right: 0;
  top: 155%;
}

.addMenu {
  height: 100%;
  width: 100%;
  background: rgb(19 57 43 / 54%);
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 4;
  transition: 0.2s opacity ease;
}

.addMenu_notBack {
  height: 82%;
  width: 100%;
  position: absolute;
  top: 175px;
  display: flex;
  justify-content: flex-end;
  z-index: 4;
  transition: 0.2s opacity ease;
}

.addMenu_block {
  height: calc(100% - 35px);
  width: max-content;
  min-width: 620px;
  background: #dce6e9;
  border-radius: 10px 0 0 10px;
  overflow: auto;
  margin: 10px 0 0;
  padding: 15px 10px 0 17px;
}

.addMenu_block_sample {
  position: fixed;
  top: 17%;
  right: 10px;
  height: 79%;
  width: max-content;
  background: #dce6e9;
  border-radius: 10px 0 0 10px;
  overflow: hidden scroll;
  margin-top: 10px;
  padding: 15px 10px 0 17px;
}

.title {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  /* min-width: 535px; */
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #e4eff2;
  margin: 0 10px 0 auto;
  cursor: pointer;
  padding: 0;
  padding: 5px;
}

.info {
  margin-left: 28px;
  margin-right: 44px;
}

.infoItem {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  color: var(--text-primary);
}

.infoItemName {
  width: 160px;
  min-width: 160px;
  margin: 0 15px 0 0;
  font-weight: 500;
  color: var(--text-primary);
}

.infoItemInput {
  width: calc(100% - 140px);
  position: relative;
  color: var(--text-primary);
}

.centerTextVertical {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.infoItem:first-child .infoItemInput {
  width: calc(100% - 198px);
  position: relative;
}

.infoItemInputBarcode {
  display: flex;
}

.infoItemValue {
  width: 100%;
  border: none;
  background: transparent;
  border-bottom: 1px solid black;
  color: var(--text-primary);
  font-family: Ubuntu-R;
  font-size: 12pt;
  padding: 0 0 6px;
}

.workRightAddMenuUserBlock1InputBlockInput {
  width: 100%;
  font-family: Ubuntu-R;
  border: none;
  border-bottom: 1px solid var(--border-gray);
  font-size: 15px;
  margin: -23px 0 0 -8px;
  display: block;
  padding: 0 0 7px;
}

.infoItemValue::-webkit-calendar-picker-indicator {
  padding-left: calc(100% - 110px);
  cursor: pointer;
  color: transparent;
  opacity: 1;
  background: url('./../../../../assets/svg/arrow-01.svg') no-repeat center right;
}

.infoItemArrow {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 15%;
  padding: 0;
}

.infoItemType {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 46px;
  min-height: 46px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1px solid rgb(0 0 0 / 20%);
  background: #6dc0a9;
  color: #1f4b3c;
  font-size: 26px;
  font-weight: 400;
  margin-left: 22px;
}

.description {
  margin-bottom: 30px;
  margin-right: 44px;
  position: relative;
  color: var(--text-primary);
}

.textarea {
  font-family: Ubuntu-R;
  font-size: 14px;
  border: none;
  width: 100%;
  height: 40px;
  margin: 12px 0 0 -2px;
  color: var(--text-secondary);
  background: transparent;
  resize: none;
  display: inline-table;
}

.functionalButtons {
  width: 85%;
  display: flex;
  align-items: center;
  margin-left: 28px;
  margin-right: 44px;
  margin-bottom: 20px;
}

.save {
  font-family: Ubuntu-R;
  font-size: 11pt;
  border-radius: 100px;
  border: 1px solid var(--color-brand);
  height: 34px;
  width: 131px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-brand);
  color: var(--bg-primary);
  cursor: pointer;
}

.cancel {
  font-family: Ubuntu-R;
  font-size: 11pt;
  border-radius: 100px;
  border: 1px solid var(--border-gray);
  height: 34px;
  width: 109px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--border-gray);
  cursor: pointer;
  margin-left: 20px;
}

.DropdownBlock {
  position: absolute;
  background: #f4f7fc;
  border-radius: 10px;
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  max-height: 115px;
  overflow: auto;
  padding: 10px 0 12px;
  z-index: 5;
  width: 210px;
}

.DropdownBlockAll {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
}

.DropdownItem {
  align-items: center;
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  font-family: Ubuntu-R;
  height: 30px;
  min-height: 30px;
  padding-left: 5px;
  position: relative;
}

.DropdownItem:hover {
  background: #a2e3df;
}

.TreeBlock {
  position: absolute;
  background: #f4f7fc;
  border-radius: 10px;
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow: auto;
  padding: 10px 10px 12px;
  z-index: 5;
  top: 28px;
  font-family: Ubuntu-R;
}

.errorInput {
  border-bottom: 1px solid #c12d2d;
}

.modalBG {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  background: rgb(19 57 43 / 54%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  font-size: 17px;
  width: 615px;
  height: 270px;
  background: var(--bg-secondary);
  border-radius: 5px;
  display: grid;
  align-content: space-between;
  grid-template-rows: 60px auto 50px;
}

.header {
  display: flex;
  justify-content: end;
  align-items: center;
}

.closeButton {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  margin: 10px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.body {
  display: grid;
  grid-template-columns: 210px auto 35px;
  height: 160px;
}

.bodyImage {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.bodyImage_self {
  height: 100%;
  width: 100%;
}

.bodyInfo {
  height: 100%;
  align-self: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.bodyInfoJE {
  justify-content: space-evenly;
}

.bodyTitle {
  font-weight: bold;
}

.bodyText {
  font-weight: normal;
}

.bodyButtons {
  display: flex;
  gap: 15px;
}

.button {
  border-radius: 50px;
  box-sizing: border-box;
  width: 130px;
  height: 36px;
  border: none;
  color: white;
  background: none;
  cursor: pointer;
  font-size: 15px;
  font-family: Ubuntu-R;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.info {
  background: #2ac49e;
}

.success {
  background: var(--color-brand);
}

.danger {
  background: #c32b2a;
}

.warning {
  background: #fe6e02;
}

.close {
  background: none;
  color: #9eabaf;
  border: solid 1px #9eabaf;
}

.container * {
  box-sizing: border-box;
}

.container span {
  padding-left: 5px;
}

.container i {
  margin-bottom: -3px;
}

.addMenu_block {
  overflow-y: scroll;
  background: #dce6e9;
  height: 420px;
  padding: 20px 10px;
  width: 100%;
  color: var(--text-primary);
}

.title {
}

.fileContainer {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.block1 {
  display: flex;
  justify-content: space-between;
}

.block1_text {
  margin: 11px 0 0;
}

.blockDefreezeText {
  margin: 11px 0 0;
  width: 100%;
  border: 1px solid;
  padding: 10px;
  text-align: center;
  background-color: #01b454;
  user-select: none;
  color: white;
  border-color: #01b454;
}

.blockDefreezeButton {
  margin: 11px 0 0;
  width: 100%;
  border: 1px solid;
  padding: 10px;
  text-align: center;
  background-color: #01b454;
  color: white;
  border-radius: 50px;
  border-color: #01b454;
  cursor: pointer;
}

.block1_items {
  display: flex;
  margin: 0 34px 0 0;
}

.block1_button {
  width: 26px;
  height: 26px;
  background: var(--color-brand);
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-position: center;
  mask-size: contain;
  mask-size: contain;
  margin: 1px 7px 0;
  cursor: pointer;
}

.block1_button_out {
  padding: 0;
  margin: 0;
  cursor: pointer;
  object-fit: cover;
  width: 150%;
  height: 100%;
}

.block1_button_outContainer {
  width: 27px;
  height: 27px;
  margin-right: 10px;
}

.block1_button_print {
  mask-image: url('../../../../assets/svg/print-01.svg');
}

.block1_button_move {
  mask-image: url('../../../../assets/svg/send-01.svg');
}

.block1_button_edit {
  mask-image: url('../../../../assets/svg/edit-02.svg');
}

.block1_button_copy {
  mask-image: url('../../../../assets/svg/copy-01.svg');
}

.block1_button_delete {
  mask-image: url('../../../../assets/svg/delete-01.svg');
}

.icon {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  font-size: 25px;
  font-family: Ubuntu-R;
}

.iconType {
  object-fit: contain;
  width: 74%;
  height: 74%;
}

.block2 {
  display: flex;
  margin: 17px 0 0 1px;
  align-items: center;
}

.number {
  font-size: 19pt;
  margin: 0 0 0 11px;
  font-family: Ubuntu-R;
}

.info_text {
  font-family: Ubuntu-R;
  font-size: 12pt;
  margin: 18px 0 0;
}

.userTextW {
  font-weight: bold;
  font-size: 17px;
  text-align: end;
}

.info_textCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.colorBlockCont {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}

.colorBlock {
  border: 1px solid #dce6e9;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 20px;
}

.info2_text {
  font-family: Ubuntu-R;
  font-size: 12pt;
  margin: 4px 0 0;
}

.block_barcode {
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: Ubuntu-R;
  font-size: 12pt;
  margin-top: 10px;
}

.separatingRow {
  margin: 20px auto;
  border: 2px dashed gray;
  text-align: center;
  padding: 10px 0;
  width: 100%;
}

.block_barcode_text {
  margin-left: 10px;
}

.userFieldContainer {
  padding-right: 10px;
  max-height: 200px;
  overflow-y: scroll;
}

.info3 {
  font-family: Ubuntu-R;
  font-size: 11pt;
  margin: 24px 0 8px;
  cursor: default !important;
}

.nameField {
  width: 50%;
}

.iconContainer {
  width: 28px;
}

.info3_text {
  margin: 0 0 4px;
}

.history {
  font-family: Ubuntu-R;
  font-size: 11pt;
  border-radius: 100px;
  border: 1px solid var(--border-gray);
  height: 34px;
  width: 195px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--border-gray);
  cursor: pointer;
}

.viewAll {
  font-family: Ubuntu-R;
  font-size: 11pt;
  border-radius: 100px;
  border: 1px solid var(--border-gray);
  height: 34px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--border-gray);
  cursor: pointer;
  margin-bottom: 30px;
}

.info_pacient {
  font-family: Ubuntu-R;
  font-size: 13pt;
  margin: 37px 0 0;
}

.info_pacient_block {
  margin: 12px 0 11px;
  max-height: 200px;
  overflow-y: scroll;
  padding-right: 10px;
}

.info_pacient_block2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 6px;
}

.info_pacient_text {
  font-family: Ubuntu-R;
  font-size: 11pt;
  width: 50%;
}

.info_pacient_text_right {
  text-align: end;
}

.blockDefreeze {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  width: 100%;
}

.disabled {
  cursor: default !important;
}

.container * {
  box-sizing: border-box;
}

.switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 26px;
  margin-left: auto;
  margin-top: -3px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: #ccc;
  transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  left: 1px;
  bottom: 1px;
  background-color: #fff;
  transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--color-brand);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--color-brand);
}

input:checked + .slider::before {
  transform: translateX(27px);
  transform: translateX(27px);
  transform: translateX(27px);
  background-image: url('../../../../assets/svg/button_on-02.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.slider {
  border-radius: 34px;
}

.slider::before {
  border-radius: 50%;
}

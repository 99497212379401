.Header {
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  padding: 9px 0 0;
  height: 85px;
  align-items: center;
  transition: 0.3s all ease;
  border-bottom: 1px solid #e8e8e8;
}

.Header_button_menu {
  background: #dce6e9;
  border-radius: 100px;
  padding: 10px 6px;
  height: 11px;
  margin: 0 0 0 4px;
  transition: 0.3s all ease;
  cursor: pointer;
}

.Header_block {
  display: flex;
  align-items: center;
  position: relative;
}

.Header_search {
  background: #f4f7fc;
  border-radius: 100px;
  height: 36px;
  width: 325px;
  display: flex;
  align-items: center;
}

.Header_search_svg {
  height: 16px;
  padding: 0 5px 0 15px;
}

.Header_search_input {
  width: 100%;
  background: #f4f7fc;
  border: none;
  font-size: 15px;
  border-radius: 0 100px 100px 0;
  font-family: Ubuntu-R;
}

.Header_bell_block {
  background: var(--color-brand);
  position: relative;
  margin: 0 20px;
  border-radius: 100px;
  height: 37px;
  width: 37px;
}

.notificWindow {
  position: absolute;
  z-index: 6000;
  background-color: #fff;
  width: 400px;
  right: 0;
  border: 2px solid var(--border-gray);
  border-radius: 10px;
  padding: 10px 0;
}

.closeContaiiner {
  position: relative;
  display: flex;
  justify-content: end;
  margin-right: 10px;
}

.closeButton {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.notificFooter {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.notificBodyRowDate {
  margin-top: 10px;
}

.notificBodyRow {
  padding: 20px 10px;
  font-size: 14px;
  font-family: Ubuntu-R;
  border-top: 1px solid black;
  background-color: var(--bg-secondary);
}

.notificBodyRowActive {
  background-color: #b0e1df;
}

.notificBodyRow:hover {
  background-color: #b0e1df;
}

.notificBodyRow:last-child {
  border-bottom: 1px solid black;
}

.notificBody {
  max-height: 400px;
  overflow-y: scroll;
}

.notificTitle {
  text-align: center;
  margin-bottom: 20px;
}

.countNotification {
  position: absolute;
  color: white;
  top: -5px;
  right: 22px;
  background-color: red;
  width: 40px;
  height: 20px;
  text-align: center;
  border-radius: 5px;
}

.Header_bell {
  mask-image: url('../../assets/svg/bell-01.svg');
  background-color: white;
  height: 37px;
  width: 38px;
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-position: center;
  cursor: pointer;
}

.Header_avatar {
  height: 60px;
  width: 60px;
  border-radius: 100px;
  border: 2px solid var(--color-brand);
}

.Header_avatar_arrow {
  margin: 0 10px;
  border: none;
  background: transparent;
}

.Header_avatar_img {
  height: 60px;
  width: 60px;
  border-radius: 100px;
  object-fit: cover;
}

.dropdownWrapper {
  position: absolute;
  top: 118%;
  right: 0%;
  width: 160px;
  background: var(--bg-primary);
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  border-radius: 0 0 10px 10px;
  z-index: 5;
}

.dropdown {
  position: relative;
  width: 100%;
  height: 100%;
}

.dropdownItem {
  padding-left: 15px;
  text-decoration: none;
  font-family: Ubuntu-R;
  cursor: pointer;
  transition: 0.3s all ease;
  font-size: 15px;
  color: #4e6c63;
  display: flex;
  align-items: center;
  height: 35px;
}

.dropdownItem:hover {
  transition: 0.3s all ease;
  background-color: #a2e3df;
}

.dropdownItem:last-child {
  height: 45px;
  background-color: var(--bg-secondary);
}

.dropdownItem:last-child:hover {
  transition: 0.3s all ease;
  background-color: #a2e3df;
}

.dropdownImg {
  padding-right: 10px;
}

.workRightTableItem {
  transition: 0.2s all ease;
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-primary);
  border-bottom: 1px solid #dce6e9;
  border-top: 1px solid #dce6e9;
  padding: 10px;
}

.workRightTableItemString {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 800;
}

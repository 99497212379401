.container {
  display: flex;
  gap: 10px;
}

.inputBarcode {
  border: none;
  border-bottom: 1px solid;
  background: none;
  height: 20px;
  width: available;
  font-size: 16px;
}

.barcodeContainer {
  width: 34%;
}

.barcodeContainerWithInput {
  width: 50%;
}

.workRightReportsAddMenu {
  height: calc(100% - 115px);
  width: 100%;
  background: rgb(255 255 255 / 100%);
  position: absolute;
  top: 100px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 1;
}

.block1 {
  display: flex;
  margin-top: 35px;
  margin-left: 55px;
  margin-right: 82px;
}

.block1_text {
  min-width: 123px;
}

.block1_input {
  border: none;
  border-bottom: 1px solid var(--border-gray);
  font-family: Ubuntu-R;
  font-size: 15px;
  margin: 0 0 0 11px;
  padding: 0 0 9px;
  width: 100%;
  letter-spacing: 0.04em;
}

.block2 {
  display: flex;
  margin-top: 18px;
  margin-left: 55px;
  margin-right: 82px;
  position: relative;
}

.arrow {
  border-bottom: 1px solid var(--border-gray);
  cursor: pointer;
}

.textArea {
  font-family: Ubuntu-R;
  font-size: 14px;
  border: none;
  width: 100%;
  height: 40px;
  margin: 12px 0 0 -2px;
  color: var(--text-secondary);
  resize: none;
  display: inline-table;
}

.block3 {
  margin-top: 52px;
  margin-left: 56px;
  margin-right: 126px;
  flex-direction: column;
  letter-spacing: 0.07em;
}

.block4 {
  display: flex;
  margin-top: 44px;
  margin-left: 58px;
  margin-right: 82px;
  flex-direction: column;

  /* height: 136px; */
  background: #f4f7fc;
  border-radius: 5px;
  border: 1px solid #b0c3c8;
  align-items: center;
  padding: 0 20px;
}

.block4_text1 {
  color: #d3dfe4;
  margin-top: 22px;
  display: flex;
  align-items: center;
}

.block4_text1 img {
  margin-right: 10px;
}

.block4_button {
  padding: 10px 0;
  border-radius: 100px;
  border: 1px solid var(--border-gray);
  font-family: Ubuntu-R;
  font-size: 11pt;
  margin: 28px 0 30px;
  width: 191px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--border-gray);
  cursor: pointer;
}

.block5 {
  font-family: Ubuntu-R;
  margin-top: 28px;
  margin-left: 60px;
  margin-right: 82px;
}

.block5_fileBlock {
  display: flex;
}

.file {
  font-size: 12pt;
}

.block5_button {
  border-radius: 100px;
  border: 1px solid;
  font-family: Ubuntu-R;
  font-size: 11pt;
  margin-top: 20px;
  height: 35px;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #29c39d;
  color: var(--bg-primary);
  cursor: pointer;
}

.block5_button img {
  padding-right: 7px;
}

.block6 {
  display: flex;
  margin-top: 14px;
  margin-left: 80px;
  margin-right: 82px;
}

.status {
  font-family: Ubuntu-R;
  border: none;
  font-size: 15px;
  display: block;
  padding: 0 0 6px;
  width: 136px;
  color: green;
  border-bottom: 1px solid black;
  letter-spacing: 0.04em;
  margin: 1px 0 0 6px;
}

.block7 {
  display: flex;
  margin-top: 60px;
  margin-left: 57px;
  margin-right: 82px;
}

.button_save {
  font-family: Ubuntu-R;
  font-size: 11pt;
  border-radius: 100px;
  border: 1px solid;
  height: 34px;
  width: 131px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-brand);
  color: var(--bg-primary);
  cursor: pointer;
}

.button_cancel {
  font-family: Ubuntu-R;
  font-size: 11pt;
  border-radius: 100px;
  border: 1px solid var(--border-gray);
  height: 34px;
  width: 109px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--border-gray);
  cursor: pointer;
  margin-left: 20px;
}

.delete_file {
  background: #f4f7fc;
  border-radius: 100px;
  width: 15px;
  height: 15px;
  padding: 7px;
  margin: -5px 10px 0;
  cursor: pointer;
}

.block1_groups {
  z-index: 5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0%;
  top: 100%;
  background: #f4f7fc;
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  max-height: 115px;
  padding: 10px 0 12px;
  overflow: auto;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.block1_groups_item {
  padding-left: 5px;
  cursor: pointer;
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  font-family: Ubuntu-R;
  color: var(--text-primary);
  position: relative;
}

.block1_groups_item:hover {
  background: #a2e3df;
}

.checkboxArrow {
  position: absolute;
  top: 35%;
  left: 20%;
}

.checkbox {
  position: relative;
}

.checkboxSquare {
  appearance: none;
  display: block;
  width: 26px;
  height: 26px;
  border: 1px solid #b0c3c8;
  background-color: var(--bg-primary);
  border-radius: 0.125rem;
  margin: 0 10px 0 0;
  transition: all 1000ms cubic-bezier(0.075, 1, 0.25, 1);
}

.checkboxSquare:checked {
  background-color: var(--color-brand);
  border: 1px solid var(--color-brand);
  transition: all 1000ms cubic-bezier(0.075, 1, 0.25, 1);
}

.checkboxSquare:checked .checkboxArrow {
  display: block;
}

.SectionItems {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 187px;
  width: calc(100% - 260px);
  margin-top: 10px;
}

.SectionItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 7px 7px 9px 12px;
  background: var(--bg-secondary);
  color: #4e5c64;
  font-size: 14px;
  border-radius: 10px;
  margin-left: 10px;
}

.SectionItem:first-child {
  margin: 0;
}

.SectionItem img {
  margin-left: 7px;
}

.errorBlock {
  position: absolute;
  display: flex;
  margin: 24px 0 0 134px;
}

.errorBlock2 {
  position: absolute;
  display: flex;
  margin: 113px 0 0;
}

.LoginErrorText2 {
  color: #c12d2d;
  font-family: Ubuntu-R;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.7;
  margin-left: 5px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
}

.row:first-child {
  margin-bottom: 20px;
}

.sizeInput {
  width: 75px;
}

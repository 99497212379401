.input {
  border: none;
  border-bottom: 1px solid var(--border-gray);
  font-family: Ubuntu-R;
  font-size: 15px;
  padding: 2px;
  width: 100%;
  letter-spacing: 0.04em;
  background: none;
}

.arrow {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
}

.content_dropdown {
  display: flex;
  position: relative;
}

.SectionItems {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2px;
}

.SectionItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 7px 7px 9px 12px;
  background: var(--bg-secondary);
  color: #4e5c64;
  font-size: 14px;
  border-radius: 10px;
  justify-content: space-between;
  flex: 0 0 29%;
}

.SectionItemDisabled {
  cursor: default;
}

.SectionItem:first-child {
  margin: 0;
}

.SectionItem img {
  margin-left: 7px;
}

.block1_groups {
  z-index: 5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0%;
  top: 100%;
  background: #f4f7fc;
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
  max-height: 115px;
  padding: 10px 0 12px;
  overflow: auto;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.block1_groups_item {
  padding-left: 5px;
  cursor: pointer;
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  font-family: Ubuntu-R;
  color: var(--text-primary);
  position: relative;
}

.block1_groups_item:hover {
  background: #a2e3df;
}

._group,
.calc,
.with_postfix {
  display: flex;
  gap: 1em;
}

._group {
  width: 100%;
  justify-content: space-between;
}

.calc {
  flex-direction: column;
}

.input,
.textarea,
.select {
  font-family: Ubuntu-R;
  font-size: 15px;
  padding: 2px;
  letter-spacing: 0.04em;
}

.input,
.textarea {
  min-width: 271px;
  overflow: hidden;
}

.select {
  border: none;
  border-bottom: 1px solid var(--border-gray);
  background: none;
  cursor: pointer;
}

.textarea {
  border: 1px solid var(--border-gray);
  background: var(--bg-textarea);
  max-width: 267px;
  resize: none;
}

.input {
  border: none;
  border-bottom: 1px solid var(--border-gray);
  background: none;
  cursor: pointer;
}

.postfix {
  width: fit-content;
}

.result {
  font-size: 24px;
  font-weight: bold;
  color: blue;
  cursor: pointer;
}

.cell:first-of-type {
  padding: 10px 15px 10px 20px;
}

.cell {
  padding: 10px 15px;
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-primary);
}

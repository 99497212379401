.container {
  background-color: white;
  border-top: 1px solid #dce6e9;
  border-bottom: 1px solid #dce6e9;
}

.table {
  border-collapse: collapse;
  width: 100%;table-layout: auto;
}

.tableContainer {
  width: 100%;
  overflow: auto;
}

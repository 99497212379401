.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.itemsCounter {
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-secondary);
}

.paging {
  display: flex;
  align-items: center;
  gap: 50px;
}

.paginationPages {
  display: flex;
}

.paginationPage {
  border: 1px solid #dce6e9;
  background: #fff;
  height: 33px;
  width: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0 0 0 -1px;
  cursor: pointer;
  user-select: none;
}

.currentPage {
  color: #fff;
  cursor: default;
  background-color: var(--color-brand); /* Этот стиль может перекрывать .active */
}

.leftArrow {
  transform: rotate(90deg);
  height: 10px;
  user-select: none;
}

.leftArrowButton {
  background: #e4eff2;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  border-radius: 20px 0 0 20px;
  border: 1px solid #dce6e9;
  cursor: pointer;
  user-select: none;
}

.leftArrowButton:disabled {
  cursor: default;
  opacity: 0.5;
}

.rightArrow {
  transform: rotate(-90deg);
  height: 10px;
}

.rightArrowButton {
  background: #e4eff2;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  border-radius: 0 20px 20px 0;
  border: 1px solid #dce6e9;
  cursor: pointer;
  margin: 0 0 0 -1px;
  user-select: none;
  user-select: none;
  user-select: none;
}

.rightArrowButton:disabled {
  cursor: default;
  opacity: 0.5;
}
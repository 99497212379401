.actionBar {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
}

.defaultActions {
  display: flex;
}

.searchInputContainer {
  background: #f4f7fc;
  border-radius: 100px;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 400px;
  position: relative;
}

.searchIcon {
  height: 15px;
  padding: 0 6px 0 7px;
}

.searchInput {
  background: #f4f7fc;
  width: 100%;
  border-radius: 0 100px 100px 0;
  border: none;
  font-size: 15px;
  font-family: Ubuntu-R;
}

.icon {
  width: 25px;
  height: 25px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 0 0 5px;
}

.columnSettingsContainer {
  position: absolute;
  right: 0;
  top: 35px;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0 5px 10px 5px rgb(89 102 109 / 17%);
  box-sizing: border-box;
  height: 200px;
  width: 340px;
  background: var(--bg-textarea);
  overflow: auto;
  padding: 16px;
}

.settings {
  position: relative;
}

.columnLabelBlock {
  padding-left: 5px;
  cursor: pointer;
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  font-family: Ubuntu-R;
  color: var(--text-primary);
  position: relative;
}

.columnLabelBlock:hover {
  background: #cef5f1;
}

.checkboxArrow {
  position: absolute;
  top: 35%;
  left: 20%;
}

.checkbox {
  position: relative;
}

.checkboxSquare {
  appearance: none;
  display: block;
  width: 26px;
  height: 26px;
  border: 1px solid #b0c3c8;
  background-color: var(--bg-primary);
  border-radius: 0.125rem;
  margin: 0 10px 0 0;
  transition: all 1000ms cubic-bezier(0.075, 1, 0.25, 1);
}

.checkboxSquare:checked {
  background-color: var(--color-brand);
  border: 1px solid var(--color-brand);
  transition: all 1000ms cubic-bezier(0.075, 1, 0.25, 1);
}

.resetColumnsButton {
  display: block;
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 10px;
  background-color: transparent;
  color: var(--color-brand);
  font-size: 14px;
  font-weight: bold;
  border: 1px solid var(--color-brand);
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.container {
  display: flex;
  gap: 2px;
}

.expandedContent {
  background: rgb(242 247 253 / 100%);
  transition: 0.2s allease;
  pointer-events: none;
}

.licenseContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.title {
  font-size: 25px;
}

.workRightManual_countRows {
  font-family: Ubuntu-R;
  font-size: 14px;
  color: var(--text-secondary);
  margin-left: 15px;
}
